import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllAbout(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [AllAboutData, setAllAboutData] = useState([]);

    axios.defaults.withCredentials = true;



    

    const getAllAbout = ()=>{
        axios.post('AllItems', {
            table: 'about_us',
        })
        .then((res) => {
            if (res.data) {
                setAllAboutData(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    





    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }









useEffect(() => {

    getAllAbout();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <table>

                    <thead>
                        <tr>
                            <th> # </th>
                            <th> Header </th>
                        </tr>
                    </thead>
                    <tbody>
                    {AllAboutData.length ? (
                    AllAboutData.map((about) => {
                        return (
                        <tr key={about.id}>
                            
                            <td>
                            <h6>{about.id}</h6>
                            </td>
                            <td>
                            <h6>{about.header}</h6>
                            </td>

                            <td><Link className='edit' to={`/edit-about?id=${about.id}`}> <i class="las la-edit"></i> </Link></td>


                        </tr>
                        );
                    })
                    ) : (
                    <tr>
                        <td colSpan={5}>Loading...</td>
                    </tr>
                    )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllAbout;