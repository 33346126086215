import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllSocialPage(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    
    const [AllSocial, setAllSocial] = useState([]);

    axios.defaults.withCredentials = true;





    const GetAllSocial = ()=>{
        axios.post('AllItems', {
            table: 'social',
        })
        .then((res) => {
            if (res.data) {
                setAllSocial(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }






useEffect(() => {

    GetAllSocial();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th> Name  </th>
                            <th> Link  </th>

                            <th>Options</th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllSocial.length ? (

                        AllSocial.map(social => {

                        return (
                            <tr key={social.id}>
                            <td><h6>{social.id}</h6></td>



                            <td><h2>{social.name}</h2></td>

                            <td><h2>{social.link}</h2></td>




                            <td>
                                <Link className='edit' to={`/edit-social?id=${social.id}`}> <i class="las la-edit"></i> </Link>
                            </td>


                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>Loading...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllSocialPage;