import './css/oursolutions.css';

import { useEffect,useState  } from "react";
import axios from '../config/index';

function OurSolutions(){

        
    const [OurSolution, setOurSolution] = useState([]);

    const getOurSolution = () => {
        let table = "solution";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setOurSolution(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


        
    const [Email, setEmail] = useState([]);

    const getEmail = () => {
        let table = "social";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setEmail(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getOurSolution();
        getEmail();
    }, []);



    return(
        <>
            <div className='our-solutions'>
                <div className='overlay'></div>
                <div className='container'>
                    <div className='our-solutions-content'>
                        <div className='text'>
                            <h4 data-aos="fade-right">  OUR SOLUTION </h4>
                            <h1 data-aos="fade-right"> {OurSolution.para} </h1>

                            <a data-aos="fade-right" href={`mailto:${Email.link}`}>
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurSolutions;