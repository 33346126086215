import './css/dashboard.css';
import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function Dashboard(){


    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    const [servicesCount, setServicesCount] = useState(0);
    const [blogsCount, setBlogsCount] = useState(0);
    const [jobsCount, setJobsCount] = useState(0);

    const [blogs, setBlogs] = useState([]);

    

    const getAllBlogs = ()=>{
        axios.post('AllItems', {
            table: 'blogs',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setBlogs(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
                navigate('/dashboard');
            }else{
                // navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);




    const getServicesCount = () => {
        axios.post('get-item-count', {
            table: 'services',
        })
        .then((res) => {
            if (res.data) {
                setServicesCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };



    
    const getBlogsCount = () => {
        axios.post('get-item-count', {
            table: 'blogs',
        })
        .then((res) => {
            if (res.data) {
                setBlogsCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };



    
    const getJobsCount = () => {
        axios.post('get-item-count', {
            table: 'jobs',
        })
        .then((res) => {
            if (res.data) {
                setJobsCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };


    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }

    
useEffect(() => {
    getAllBlogs();
    getBlogsCount();
    getJobsCount();
    getServicesCount();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>
                <div className='stats-content'>
                    <Link to="/all-services">
                        <div className='stat'>
                            <i className="las la-users"></i>
                            <div className='info'>
                                <h1>{servicesCount}</h1>
                                <h2> Services </h2>
                            </div>
                        </div>
                    </Link>
                    

                    <Link to="/all-blogs">
                        <div className='stat'>
                        <i className="las la-question"></i>
                            <div className='info'>
                                <h1>{blogsCount}</h1>
                                <h2> Blogs  </h2>
                            </div>
                        </div>
                    </Link>


                    <Link to="/all-jobs">
                        <div className='stat'>
                        <i className="las la-poll-h"></i>
                            <div className='info'>
                                <h1>{jobsCount}</h1>
                                <h2> Jobs </h2>
                            </div>
                        </div>
                    </Link>

                    
                </div>




                <div className='results-table'>

                <table>

                    <thead>
                        <tr>
                            <th> # </th>
                            <th> IMG </th>
                            <th> Header </th>
                            <th> options </th>

                        </tr>
                    </thead>
                    <tbody>

                    {blogs && blogs.length > 0 ? (
                        blogs.map((blog) => (
                            <tr key={blog.id}>
                            <td><h6>{blog.id}</h6></td>
                            <td><img src={`/uploads/${blog.img}`} alt="Product Img"/></td>
                            <td><h2>{blog.header}</h2></td>

                            <td><Link to={`/edit-blog?id=${blog.id}`}> Edit </Link></td>
                            </tr>
                        ))
                        ) : (
                        <tr>
                            <td colSpan="4"> There is no blogs to show ... </td>
                        </tr>
                        )}

                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default Dashboard;