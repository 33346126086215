import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import Landing from '../Landing';




function AllServices(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [AllServices, setAllServices] = useState([]);
    const [AllCategories, setAllCategories] = useState([]);

    axios.defaults.withCredentials = true;





    const getAllServices = ()=>{
        axios.post('AllItems', {
            table: 'services',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllServices(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    



    



    const getAllCategories = ()=>{
        axios.post('AllItems', {
            table: 'categories',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllCategories(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    






    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'services',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                getAllServices();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };




useEffect(() => {

    getAllServices();
    getAllCategories();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <Link className='new-item' to='/add-service'> +  Add New Service </Link>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>IMG</th>
                            <th> Header </th>
                            <th> Category </th>


                            <th>Options</th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllServices.length ? (

                        AllServices.map(service => {
                            const category = AllCategories.find(cat => cat.id === service.cat_id);

                        return (
                            <tr key={service.id}>
                            <td><h6>{service.id}</h6></td>

                            <td><img src={`/uploads/${service.img}`} alt="User avatar"/></td>
                            <td><h6>{service.header}</h6></td>

                            <td><h6>{category ? category.header : 'Category not found'}</h6></td> {/* Display the category name if found */}



                            <td>
                                <Link className='edit' to={`/edit-service?id=${service.id}`}> <i class="las la-edit"></i> </Link>
                            </td>

                            
                            <td>
                                <button className='delete' onClick={() => deleteItem(service.id)}> <i class="las la-trash-alt"></i> </button>
                            </td>

                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>Loading...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllServices;