import './css/footer.css';
import Logo from './images/whitelogo.png';
import { Link as RouterLink } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import {useState  } from "react";
import axios from '../config/index';



function Footer(){

    const location = useLocation(); // Get the current location from react-router

    useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
    }, [location.pathname]); // Re-run this effect when the pathname changes



    
    const [Blogs, setBlogs] = useState([]);

    const getAllblogs = () => {
        axios.post("AllItems", {
            table: "blogs",
        })
        .then((res) => {
            if (res.data) {
                setBlogs(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };


    const [Services, setServices] = useState([]);

    const getAllServices = () => {
        axios.post("AllItems", {
            table: "services",
        })
        .then((res) => {
            if (res.data) {
                setServices(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };


            
    const [Email, setEmail] = useState([]);

    const getEmail = () => {
        let table = "social";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setEmail(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


            
    const [facebook, setFacebook] = useState([]);

    const getFacebook = () => {
        let table = "social";
        let itemId = 2;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setFacebook(res.data);
                }
            })
            .catch((err) => console.log(err));
    };



            
    const [twitter, setTwitter] = useState([]);

    const getTwitter = () => {
        let table = "social";
        let itemId = 3;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setTwitter(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




            
    const [linkedin, setLinkedin] = useState([]);

    const getLinkedin = () => {
        let table = "social";
        let itemId = 4;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setLinkedin(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




            
    const [instagram, setInstagram] = useState([]);

    const getInstagram = () => {
        let table = "social";
        let itemId = 5;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setInstagram(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    
    useEffect(() => {
        getAllblogs();
        getAllServices();
        getFacebook();
        getTwitter();
        getLinkedin();
        getInstagram();
        getEmail();

    }, []);


    return(
        <footer>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <div className='logo'>
                                <img src={Logo} alt="logo uc content" />
                            </div>

                            <p>
                                ofsaera Financial Excellence Through Technical Expertise
                            </p>
                            <a href={`mailto:${Email.link}`}> {Email.link} </a>
                            <a href="https://ofsaera.com"> www.ofsaera.com </a>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <h1> Quick Links </h1>
                            <ul>
                            <li>
                                    <RouterLink to="/">
                                        Home
                                    </RouterLink>
                                </li>

                                
                                <li>
                                    <RouterLink to="/about-us">
                                        About Us
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/services">
                                        Services
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/our-products">
                                        Products
                                    </RouterLink>
                                </li>


                                <li>
                                    <RouterLink to="/blogs">
                                        Blogs
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/contact-us">
                                        Contact Us
                                    </RouterLink>
                                </li>
                                
                                <li>
                                    <RouterLink to="/ar">
                                        العربية
                                    </RouterLink>
                                </li>
                                


                            </ul>
                        </div>
                    </div>


                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <h1> Services </h1>
                            <ul>
                            {Services.slice(0,4).map((service, index) => (
                                <li key={index}>
                                    <RouterLink to="/services">
                                        {service.header}
                                    </RouterLink>
                                </li>
                            ))}
                        </ul>

                        </div>
                    </div>



                    <div className='col-lg-3 col-md-3 col-sm-12 co-12'>
                        <div className='footer-content'>
                            <h1> Blogs </h1>
                            <ul>
                            {Blogs.slice(0,6).map((blog, index) => (
                                    <li key={index}>
                                        <RouterLink to={`/blog?id=${blog.id}`}>
                                            {blog.header}
                                        </RouterLink>
                                    </li>
                                ))}
                            
                                

                            </ul>
                        </div>
                    </div>



                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='copy-right'>
                            <h3> © 2024 OFSAERA. All Rights Reserved. </h3>

                            <div className='socials'>
                                
                                <a target='_blank' href={facebook.link}>
                                    <i class="lab la-facebook-f"></i>
                                </a>

                                <a target='_blank' href={twitter.link}>
                                    <i class="lab la-twitter"></i>
                                </a>

                                <a target='_blank' href={linkedin.link}>
                                    <i class="lab la-linkedin-in"></i>
                                </a>


                                <a target='_blank' href={instagram.link}>
                                    <i class="lab la-instagram"></i>
                                </a>


                                <a target='_blank' href={`mailto:${Email.link}`}>
                                    <i class="las la-envelope"></i>
                                </a>


                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </footer>
    )   
}

export default Footer;