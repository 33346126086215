import './css/contactinfo.css';

import React, { useState,useEffect } from 'react';
import axios from '../config/index';
function ContactInfo(){


    const [Email, setEmail] = useState([]);

    const getEmail = () => {
        let table = "social";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setEmail(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    const [Address, setAddress] = useState([]);

    const getAddress = () => {
        let table = "social";
        let itemId = 7;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setAddress(res.data);
                }
            })
            .catch((err) => console.log(err));
    };



    const [Branches, setBranches] = useState([]);

    const getBranches = () => {
        let table = "social";
        let itemId = 6;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setBranches(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    
    const [phonenumber, setphonenumber] = useState([]);

    const getphonenumber = () => {
        let table = "social";
        let itemId = 8;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setphonenumber(res.data);
                }
            })
            .catch((err) => console.log(err));
    };



        
    useEffect(() => {
        getEmail();
        getAddress();
        getBranches();
        getphonenumber();
    }, []);


    return(
        <>
            <section className='contact-info-page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div data-aos-delay="100" data-aos="fade-up" className='info'>
                                <i class="las la-code-branch"></i>
                                <h3> Our Branches </h3>
                                <a href="#">
                                    {Branches.link}
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div data-aos-delay="200" data-aos="fade-up" className='info'>
                                <i class="las la-map-marked"></i>
                                <h3> Address </h3>
                                <a href="#">
                                {Address.link}
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div data-aos-delay="300" data-aos="fade-up" className='info'>
                            <i class="las la-mail-bulk"></i>
                                <h3> Contact Email </h3>
                                <a href={`mailto:${Email.link}`}>
                                {Email.link}
                                </a>
                            </div>
                        </div>


                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div data-aos-delay="400" data-aos="fade-up" className='info'>
                                <i class="las la-phone-volume"></i>
                                <h3> Phone number </h3>
                                <a href={`tel:${phonenumber.link}`}>
                                {phonenumber.link}
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}
export default ContactInfo;