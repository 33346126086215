import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditLandingSlide() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [LandingSlide, setLandingSlide] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        sec_header: "",
        header: "",
        para: "",

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (LandingSlide.id) {
        setFormInputs({
            sec_header: LandingSlide.sec_header,
            header: LandingSlide.header,
            para: LandingSlide.para,
        });
        }
    }, [LandingSlide]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { sec_header, header, para } = formInputs;
        
            if (!sec_header || !header || !para ) {
            return alert("All fields are required");
            }
        
            try {
            const form = new FormData();
            form.append("id", id);
            form.append("sec_header", sec_header);
            form.append("header", header);
            form.append("para", para);
        
            const response = await axios.post("/update-landing-text", form);
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setLandingSlide((prevLandingSlide) => ({
                ...prevLandingSlide,
                header, sec_header,para,
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getLandingSlide = (itemId) => {
        let table = "landing_text";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setLandingSlide(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getLandingSlide(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getLandingSlide(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                
                <div className='alert-success'>
                        <h1> Data Added successfully  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> Failed To Add Data <i class="las la-exclamation-triangle"></i></h1>

                    </div>


                    <form onSubmit={handleSubmit}>
                
                        <div className='input'>
                            <label> Sub Header  </label>
                            <input  value={formInputs.sec_header} onChange={handleChange} type='text' required="required"  maxLength="200" name='sec_header'/>
                        </div>

            
                        <div className='input'>
                            <label> Header  </label>
                            <input value={formInputs.header} onChange={handleChange} type='text' required="required"  maxLength="200" name='header'/>
                        </div>


                        <div className='input'>
                            <label> Text  </label>
                            <textarea value={formInputs.para} onChange={handleChange} type='text' required="required"  maxLength="700" name='para'>
                                
                            </textarea>
                        </div>

                                <button disabled={!formChanged} type='submit'> Update </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditLandingSlide;
