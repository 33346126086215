import './css/ourservices.css';

import { Link as RouterLink } from 'react-router-dom';

import { useEffect,useState  } from "react";
import axios from '../config/index';


function OurServices(){

    
    
    const [Services, setAllServices] = useState([]);

    const getAllServices = () => {
        axios.post("AllItems", {
            table: "services",
        })
        .then((res) => {
            if (res.data) {
                setAllServices(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    const [Categories, setAllCategories] = useState([]);

    const getAllCategories = () => {
        axios.post("AllItems", {
            table: "categories",
        })
        .then((res) => {
            if (res.data) {
                setAllCategories(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllCategories();
        getAllServices();
    }, []);



    return(
        <section className='our-services'>
            <div className='container'>

            {Categories.length === 0 ? (
                <p>No categories available</p>
            ) : Categories.map((category, index) => {
                const relatedServices = Services.filter(service => service.cat_id === category.id);

                if (relatedServices.length === 0) {
                    return <p key={category.id}>No services available for {category.header}</p>;
                }

                const isCategoryLeft = index % 2 === 0;

                return (
                    <div className='row' key={category.id}>
                        {isCategoryLeft && (
                            <>

                            <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                                <div className='header'>
                                    <h1 data-aos="fade-up">{category.header}  </h1>

                                    <p data-aos="fade-up">
                                        {category.para}
                                    </p>

                                    <div data-aos="fade-up">
                                        <RouterLink  to="/blogs">
                                            Our Blog
                                        </RouterLink>

                                        <RouterLink to="/services">
                                            Our Services  <i class="las la-long-arrow-alt-right"></i>
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>


                            </>
                        )}

                        <div className='col-lg-9 col-md-9 col-sm-12 col-12'>
                            <div className='row'>
                                {relatedServices.map((service) => (
                                    <div className='col-lg-4 col-md-4 col-sm-12 col-12' key={service.id}>
                                        <div data-aos-delay="100" data-aos="zoom-in" className='service'>
                                            <img src={`uploads/${service.img}`} alt="service image" />
                                            <h1>{service.header}</h1>
                                            <p>{service.para}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {!isCategoryLeft && (
                            <>

                            <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                                <div className='header'>
                                    <h1 data-aos="fade-up">{category.header}  </h1>

                                    <p data-aos="fade-up">
                                        {category.para}
                                    </p>

                                    <div data-aos="fade-up">
                                        <RouterLink  to="/blogs">
                                            Our Blog
                                        </RouterLink>

                                        <RouterLink to="/services">
                                            Our Services  <i class="las la-long-arrow-alt-right"></i>
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>


                            </>
                        )}
                    </div>
                );
            })}




            </div>
        </section>
    )
}

export default OurServices;