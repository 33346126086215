import './css/contact.css';


import React, { useState,useEffect } from 'react';
import axios from '../config/index';
import ReCAPTCHA from "react-google-recaptcha";


function Contact(){

    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const onRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const [Email, setEmail] = useState([]);

    const getEmail = () => {
        let table = "social";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setEmail(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

        
    useEffect(() => {
        getEmail();
    }, []);


    const initialFormData = {
        name: '',
        email: '',
        subject: '',
        message: '',
    };
    
    const [formData, setFormData] = useState(initialFormData);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };
    
    const resetForm = () => {
        setFormData(initialFormData);
    };
    
    const handleSubmit = async (e) => {
        
        e.preventDefault();

        if (!recaptchaValue) {
            alert('Please verify that you are not a robot.');
            return;
        }


        try {
        const response = await axios.post('/send-email', formData, {
            headers: {
            'Content-Type': 'application/json',
            },
        });
    
        if (response.status === 200) {
            // Successful submission, you can display a success message or redirect the user
            alert('Message sent successfully!');
            // Reset the form
            resetForm();
        } else {
            // Handle server error or validation errors
            alert('Failed to send message. Please try again later.');
        }
        } catch (error) {
        // Handle network error or other errors
        console.error('Error:', error);
        alert('An error occurred while sending the message.');
        }
    };


    return(
        <>
            <section className='contact'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div  className='contact-content'>
                                <div data-aos="fade-right" className='email-info'>
                                    <i class="lar la-envelope"></i>

                                    <div>
                                        <h3> Say hello </h3>
                                        <a href={`mailto:${Email.link}`}>
                                            {Email.link}
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='contact-content'>
                                <div data-aos="fade-left" className='form'>
                                    <h1> 
                                        Need a hand?
                                    </h1>
                                    <p>
                                        Reach out to the world’s most reliable IT services.
                                    </p>

                                    <form onSubmit={handleSubmit}>
                                        <div className='inputs'>
                                            <input value={formData.name} onChange={handleChange} placeholder='Name * ' type='text' required name="name" />
                                            <input value={formData.email} onChange={handleChange} placeholder='Email * ' type='email' required name="email" />
                                        </div>

                                        <div className='inputs'>
                                            <input  value={formData.subject} onChange={handleChange} placeholder='Subject * ' type='text' required name="subject" />
                                        </div>

                                        <div className='inputs'>
                                            <textarea  value={formData.message} onChange={handleChange} placeholder='Message * ' required name="message">

                                            </textarea>
                                        </div>
                                        <ReCAPTCHA
                                            sitekey="6Ld6VTopAAAAADXRLsja3xBaofVeDD0TNWV4WEfr"
                                            onChange={onRecaptchaChange}
                                        />

                                        <button aria-label="Send Message Form" id="submit-button" type="submit">
                                            Send message 
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7292974.47331492!2d36.170319469847506!3d26.807406078225462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14368976c35c36e9%3A0x2c45a00925c4c444!2z2YXYtdix!5e0!3m2!1sar!2seg!4v1702628260561!5m2!1sar!2seg" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;