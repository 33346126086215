import './css/whychooseus.css';

import { useEffect,useState  } from "react";
import axios from '../config/index';

function WhyChooseUs(){

    
    const [Benefits, setBenefits] = useState([]);

    const getBenefits = () => {
        let table = "benefits";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setBenefits(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


        
    const [Email, setEmail] = useState([]);

    const getEmail = () => {
        let table = "social";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setEmail(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getBenefits();
        getEmail();
    }, []);





    return(
        <>
            <section className='why-choose-us'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='header'>
                                <h3 data-aos="fade-up"> Why Choose Ofsaera ? </h3>

                                <h1 data-aos="fade-up">
                                    Unlocking Success: Benefits of Choosing OFSAERA
                                </h1>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='reason'>
                                <i class="las la-route"></i>
                                <h1 > 
                                    {Benefits.header_1}
                                </h1>
                                <p>
                                {Benefits.para_1}

                                </p>

                                <a href={`mailto:${Email.link}`}>
                                    Know More <i class="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='reason'>
                                <i class="las la-users"></i>
                                <h1 > 
                                    {Benefits.header_2}
                                </h1>
                                <p>
                                {Benefits.para_2}

                                </p>

                                <a href={`mailto:${Email.link}`}>
                                    Know More <i class="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div data-aos="zoom-in" className='reason'>
                                <i class="las la-chart-line"></i>
                                <h1 > 
                                    {Benefits.header_3}
                                </h1>
                                <p>
                                {Benefits.para_3}

                                </p>

                                <a href={`mailto:${Email.link}`}>
                                    Know More <i class="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs;