import './css/aboutus.css';
import AboutImg from './images/aboutus.webp'
import { Link as RouterLink } from 'react-router-dom';

import { useEffect,useState  } from "react";
import axios from '../config/index';

function Aboutus(){

        
    const [AboutText, setAboutText] = useState([]);

    const getAboutText = () => {
        let table = "about_us";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setAboutText(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


        
    const [Email, setEmail] = useState([]);

    const getEmail = () => {
        let table = "social";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setEmail(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAboutText();
        getEmail();
    }, []);



    return(
        <>
            <section className='about'>
                <div className='container'>
                    <div className='about-content'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='header'>
                                    <h3  data-aos="fade-right"> About Ofsera </h3>
                                    <h1  data-aos="fade-right"> 
                                    About <span>OFSAERA </span>: Pioneering Financial Excellence through <span>Technical Mastery</span>
                                    </h1>
                                </div>
                            </div>


                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='about-img'>
                                    <div className='image' data-aos="fade-up">
                                        {AboutText.video ? (
                                            <video autoPlay muted controls loop>
                                                <source src={`uploads/videos/${AboutText.video}`} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <img src={AboutImg} alt="About Image" />
                                        )}
                                    </div>

                                    <div className='text'>
                                        <p  data-aos="fade-right">
                                            {AboutText.para_2}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='about-text'>
                                    <h2  data-aos="fade-up"> {AboutText.header} </h2>
                                    <p  data-aos="fade-up">
                                    {AboutText.para_1}
                                    </p>

                                    <a  data-aos="fade-up" href={`mailto:${Email.link}`}>
                                        Talk to a consultant
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutus;