import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';






function AddServices(){


        
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);





    const navigate = useNavigate();


    
    const [header, setHeader] = useState("");
    const [para, setPara] = useState("");
    const [cat_id, setCatId] = useState("");

    const [img, setImg] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();

        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");

        const data = new FormData();


        data.append("header", header || "");
        data.append("para", para || "");
        data.append("cat_id", cat_id || "");

        if (img) {
            data.append("img", img);
        }
    
    
        const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        };
    
        axios
        .post("/add-service", data, config)
        .then((response) => {
            
            Loading.classList.remove("active");

            let alertS = document.querySelector(".alert-success"); 
            alertS.classList.add("active");


            setTimeout(() => {
                alertS.classList.remove("active");
            }, 3000);


        })
        .catch((error) => {
            
            Loading.classList.remove("active");

            let alertD = document.querySelector(".alert-danger"); 
            alertD.classList.add("active");
            setTimeout(() => {
                alertD.classList.remove("active");
            }, 3000);
            console.log(error);
        });
    };
    


    axios.defaults.withCredentials = true;



    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }
        
    })
    .catch((err) => console.log(err));

    }


    const [AllCategories, setAllCategories] = useState([]);

    
    const getAllCategories = ()=>{
        axios.post('AllItems', {
            table: 'categories',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllCategories(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getAllCategories();
    }, []);
    




    

    return(
        <>

                    <div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>



                <div className='edit-form'>

                    <div className='alert-success'>
                        <h1> Data Added successfully <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> Failed To Add Data <i class="las la-exclamation-triangle"></i></h1>

                    </div>

                    <h1> Add new Service </h1>

                    <form onSubmit={handleSubmit}>

                        
                    <div className='input'>
                        <label> Service Name  </label>
                        <input  onChange={(event) => setHeader(event.target.value)} type='text' required="required"  maxLength="250" name='header'/>
                    </div>



                    <div className='input'>
                        <label> Service Description  </label>
                        <textarea  onChange={(event) => setPara(event.target.value)} type='text' required="required"  maxLength="700" name='para'>
                            
                        </textarea>
                    </div>

                    
                    <div className='input'>
                        <label> Service Category  </label>
                        <select  onChange={(event) => setCatId(event.target.value)} required="required"  name='cat_id'>
                            <option value=""> Choose Category </option>
                            {
                            AllCategories.map((category) => (
                                <option key={category.id} value={category.id}>{category.header}</option>
                            ))
                            }


                        </select>
                    </div>


                    <div className='input'>
                        <label> Image  </label>
                        <input onChange={(event) => setImg(event.target.files[0])} type='file' name="img" accept="image/*"/>
                    </div>

                
                    <button type='submit'> Save </button>

                    </form>
                </div>
            </section>
        
        </>
    )
}

export default AddServices;