import './css/careers.css';
import pageHeaderImg4 from './images/2.webp';
import ReCAPTCHA from "react-google-recaptcha";
import PageHeader from './PageHeader';
import React, { useState,useEffect } from 'react';
import axios from '../config/index';

function Careers(){


    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const onRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };


    const [Jobs, setJobs] = useState([]);

    const getAllJobs = () => {
        axios.post("AllItems", {
            table: "jobs",
        })
        .then((res) => {
            if (res.data) {
                setJobs(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    
    useEffect(() => {
        getAllJobs();

    }, []);


    const initialFormData = {
        name: '',
        email: '',
        phonenumber: '',
        education: '',
        yearsofexp: '',
        currentorg: '',
        currentlocation: '',
        message: '',
    };
    
    const [formData, setFormData] = useState(initialFormData);
    const [selectedFile, setSelectedFile] = useState(null); // State for the resume file
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!recaptchaValue) {
            alert('Please verify that you are not a robot.');
            return;
        }
    
        // Create a FormData object and append the form data
        const data = new FormData();
        Object.keys(formData).forEach(key => {
            data.append(key, formData[key]);
        });
    
        // Append the resume file
        // Ensure 'selectedFile' is set to the resume file from your file input
        data.append('resume', selectedFile);
    
        try {
            const response = await axios.post('/send-job-application', data);
    
            if (response.status === 200) {
                alert('Application sent successfully!');
                resetForm();
            } else {
                alert('Failed to send application. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while sending the application.');
        }
    };


    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]); // Set the file
    };
    
    const resetForm = () => {
        setFormData(initialFormData);
        setSelectedFile(null); // Also reset the file input
    };
    


    return(
        <>
        <PageHeader link1="Home" link2="Careers" title="Careers" img={pageHeaderImg4} />

            <section className='careers'>
                <div className='container'>
                    <div className='careers-content'>
                        <div className='all-jobs'>
                            <div className='header'>
                                <h1 data-aos="fade-right">Current Employment Opportunities</h1>
                            </div>

                            {
                                Jobs && Jobs.length > 0 && (
                                    Jobs.map((Job, index) => (
                                        
                                    <div  data-aos="fade-up" className='job'>
                                            <img src={`uploads/${Job.img}`} alt="job image" />
                                            <div className='info'>
                                                <h2> {Job.header} </h2>
                                                <p>
                                                    {Job.para}
                                                </p>
                                                <a href="#jobapply">Apply </a>
                                            </div>
                                        </div>
                                    ))
                                )
                            }

                        </div>


                    <div id="jobapply" data-aos="fade-right" className='form'>
                        <form onSubmit={handleSubmit}>
                            <div className='inputs'>
                                <input value={formData.name} onChange={handleChange} placeholder='Name * ' type='text' required name="name" />
                                <input value={formData.email} onChange={handleChange} placeholder='Email * ' type='email' required name="email" />
                                <input value={formData.phonenumber} onChange={handleChange} placeholder='Phone Number * ' type='text' required name="phonenumber" />
                            </div>

                            <div className='inputs'>
                                <input value={formData.education} onChange={handleChange} placeholder='Education * ' type='text' required name="education" />
                                <input value={formData.yearsofexp} onChange={handleChange} placeholder='Years of Experience * ' type='text' required name="yearsofexp" />
                            </div>

                            <div className='inputs'>
                                <input value={formData.currentorg} onChange={handleChange} placeholder='Current Organization ' type='text' name="currentorg" />
                                <input value={formData.currentlocation} onChange={handleChange} placeholder='Current Location ' type='text' name="currentlocation" />
                            </div>

                            <div className='inputs'>
                                <textarea value={formData.message} onChange={handleChange} placeholder='Message * ' required name="message"></textarea>
                            </div>

                            <div className='inputs'>
                                <label> Pdf Only *  </label>
                                <input type="file" onChange={handleFileChange} required name="resume" />
                            </div>

                            <ReCAPTCHA
                                sitekey="6Ld6VTopAAAAADXRLsja3xBaofVeDD0TNWV4WEfr"
                                onChange={onRecaptchaChange}
                            />

                            <button aria-label="Send Job Application Form" id="submit-button" type="submit">
                                Send Application
                            </button>
                        </form>
                    </div>





                    </div>
                </div>
            </section>
        </>
    )
}

export default Careers;