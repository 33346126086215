import './css/landing.css';

import LandingVid from './videos/landing.mp4';

import { Link as RouterLink } from 'react-router-dom';

import { useEffect,useState  } from "react";
import axios from '../config/index';

function Landing(){


    
    const [LandingText, setLandingText] = useState([]);

    const getLandingText = () => {
        let table = "landing_text";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setLandingText(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


        
    const [Email, setEmail] = useState([]);

    const getEmail = () => {
        let table = "social";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setEmail(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getLandingText();
        getEmail();
    }, []);



    return(
        <>
            <section data-aos="zoom-in" className='landing'>
                <video  src={LandingVid} loop autoPlay muted></video>
                <div className='overlay'></div>
                <div className='container'>
                    <div className='landing-content'>
                        <h1   data-aos-delay="1200" data-aos="fade-up"> {LandingText.sec_header}  </h1>
                        <h2 data-aos-delay="1200" data-aos="fade-up"> {LandingText.header} </h2>
                        <p data-aos-delay="1200" data-aos="fade-up"> 
                            {LandingText.para}
                        </p>

                        <div data-aos-delay="1200" data-aos="fade-up">
                            <RouterLink to='/services'> Our Services </RouterLink>

                            <a href={`mailto:${Email.link}`}>
                                <i class="las la-play-circle"></i>
                                How to start ? 
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Landing;