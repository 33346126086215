import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Navbar from './components/Navbar';
import Landing from './components/Landing';
import Aboutus from './components/Aboutus';
import WhyChooseUs from './components/WhyChooseUs';
import OurSolutions from './components/OurSolutions';
import OurServices from './components/OurServices';
import OurBlogs from './components/OurBlogs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import OurProducts from './components/OurProducts';

import PageHeader from './components/PageHeader';
import BlogPage from './components/BlogPage';
import ProductPage from './components/ProductPage';


import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';


import pageHeaderImg1 from './components/images/1.webp';
import pageHeaderImg2 from './components/images/2.webp';
import pageHeaderImg3 from './components/images/3.webp';
import pageHeaderImg4 from './components/images/4.webp';






// Admin Pages


import SideNav from "./components/Admin/SideNav";
import LoginAdmin from "./components/Admin/LoginAdmin";


import SearchBar from "./components/Admin/SearchBar";
import Dashboard from "./components/Admin/Dashboard";
import AllUsersPage from "./components/Admin/AllUsersPage";
import EditUser from "./components/Admin/EditUser";
import AddUser from "./components/Admin/AddUser";


import AllLandingSlides from './components/Admin/AllLandingSlides';
import EditLandingSlide from './components/Admin/EditLandingSlide';
import AddLandingSlide from './components/Admin/AddLandingSlide';

import AllServices from './components/Admin/AllServices';
import AddServices from './components/Admin/AddServices';
import EditServices from './components/Admin/EditServices';

import AllNumbers from './components/Admin/AllNumbers';
import EditNumbers from './components/Admin/EditNumbers';

import AllBenefits from './components/Admin/AllBenefits';
import EditBenefits from './components/Admin/EditBenefits';
import AllVideos from './components/Admin/AllVideos';
import AddVideos from './components/Admin/AddVideos';
import EditVideos from './components/Admin/EditVideos';
import AllTimeTables from './components/Admin/AllTimeTables';
import EditTimeTables from './components/Admin/EditTimeTables';

import AddCategory from './components/Admin/AddCategory';
import AllCategories from './components/Admin/AllCategories';
import EditCategory from './components/Admin/EditCategory';

import AddGallery from './components/Admin/AddGallery';
import AllGalleryPage from './components/Admin/AllGalleryPage';
import EditGallery from './components/Admin/EditGallery';


import EditAbout from './components/Admin/EditAbout';
import AllAbout from './components/Admin/AllAbout';


import EditSolution from './components/Admin/EditSolution';
import AllSolutions from './components/Admin/AllSolutions';


import EditSocial from './components/Admin/EditSocial';
import AllSocialPage from './components/Admin/AllSocialPage';
import AllGifts from './components/Admin/AllGifts';
import AllCurrency from './components/Admin/AllCurrency';
import EditCurrency from './components/Admin/EditCurrency';


import AllOrders from './components/Admin/AllOrders';
import ViewOrders from './components/Admin/ViewOrders';

import AddProduct from './components/Admin/AddProduct';
import AllProducts from './components/Admin/AllProducts';
import EditProduct from './components/Admin/EditProduct';

import AddReview from './components/Admin/AddReview';
import AllReviews from './components/Admin/AllReviews';
import EditReview from './components/Admin/EditReview';

import AddBlog from './components/Admin/AddBlog';
import EditBlog from './components/Admin/EditBlog';
import AllBlogs from './components/Admin/AllBlogs';


import AddJob from './components/Admin/AddJob';
import EditJob from './components/Admin/EditJob';
import AllJobs from './components/Admin/AllJobs';
import Careers from './components/Careers';
import ContactInfo from './components/ContactInfo';





function App() {

    
    const [sessionExists, setSessionExists] = useState("");
    const servicesRef = useRef(0);
    const location = useLocation();
    const hideHeaderFooter =
    location.pathname === "/dashboard" ||
    location.pathname === "/all-users" ||
    location.pathname === "/all-waiting-users" ||
    location.pathname === "/edit-user" ||
    location.pathname === "/add-user"||
    location.pathname === "/all-landing-slides"||
    location.pathname === "/add-landing-slide"||
    location.pathname === "/edit-landing-slide"||
    location.pathname === "/all-categories"||
    location.pathname === "/add-category"||
    location.pathname === "/edit-category"||
    location.pathname === "/all-numbers"||
    location.pathname === "/edit-number"||
    location.pathname === "/all-benefits"||
    location.pathname === "/edit-benefit"||
    location.pathname === "/all-videos"||
    location.pathname === "/add-video"||
    location.pathname === "/edit-video"||
    location.pathname === "/all-time-tables"||
    location.pathname === "/edit-time-table"||
    location.pathname === "/all-sub-services"||
    location.pathname === "/add-sub-service"||
    location.pathname === "/edit-sub-service"||
    location.pathname === "/all-gallery"||
    location.pathname === "/add-gallery"||
    location.pathname === "/edit-gallery" ||   
    location.pathname === "/all-services"||
    location.pathname === "/add-service"||
    location.pathname === "/edit-service" ||   
    location.pathname === "/all-about"||
    location.pathname === "/edit-about"||
    location.pathname === "/all-social"||
    location.pathname === "/all-orders"||
    location.pathname === "/all-gifts"||
    location.pathname === "/edit-currency"||
    location.pathname === "/all-currency"||
    location.pathname === "/all-orders"||
    location.pathname === "/view-orders"||
    location.pathname === "/view-orders"||
    location.pathname === "/all-products"||
    location.pathname === "/edit-product"||
    location.pathname === "/add-product"||
    location.pathname === "/all-reviews"||
    location.pathname === "/edit-review"||
    location.pathname === "/add-blog"||
    location.pathname === "/all-blogs"||
    location.pathname === "/edit-blog"||
    location.pathname === "/add-job"||
    location.pathname === "/all-jobs"||
    location.pathname === "/edit-job"||
    location.pathname === "/all-solutions"||
    location.pathname === "/edit-solution"||
    location.pathname === "/add-review"||
    location.pathname === "/edit-social";




    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, [])
    
return (
    <div className="App">

        
<SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        />


            <Routes>
                

            <Route path="/" element={
                    <>
                                <Navbar />

                        <Landing />
                        <Aboutus />
                        <WhyChooseUs />
                        <OurSolutions />
                        <OurServices />
                        <OurBlogs />
                        <Contact />
                        <Footer />


                    </>
                } />

                <Route path="/about-us" element={
                    <>
                                <Navbar />

                        <PageHeader link1="Home" link2="About us" title="About Us" img={pageHeaderImg1} />
                        <Aboutus />
                        <Contact />
                        <Footer />

                    </>
                } />


            <Route path="/services" element={
                    <>
                                <Navbar />

                        <PageHeader link1="Home" link2="Services" title="Services" img={pageHeaderImg2} />
                        <OurServices />
                        <Contact />
                        <Footer />

                    </>
                } />
                

            


                
            <Route path="/our-products" element={
                    <>
                        <Navbar />
                        <OurProducts />
                        <Contact />
                        <Footer />

                    </>
                } />


            
            <Route path="/blogs" element={
                    <>
                                <Navbar />

                        <PageHeader link1="Home" link2="blogs" title="Our Blogs" img={pageHeaderImg3} />
                        <OurBlogs />
                        <Contact />
                        <Footer />

                    </>
                } />

            <Route path="/contact-us" element={
                    <>
                                <Navbar />
                        <PageHeader link1="Home" link2="Contact Us" title="Contact Us" img={pageHeaderImg4} />
                        <ContactInfo />

                        <Contact />
                        <Footer />

                    </>
                } />


            <Route path="/blog" element={
                    <>
                                <Navbar />

                        <BlogPage />
                        <Footer />

                    </>
                } />


                
            <Route path="/product" element={
                    <>
                                <Navbar />

                        <ProductPage />
                        <Footer />

                    </>
                } />

            <Route path="/careers" element={
                    <>
                                <Navbar />

                        <Careers />
                        <Footer />

                    </>
                } />






                
<Route path="/admin-login"element={<><LoginAdmin sessionExists={sessionExists} setSessionExists={setSessionExists} /></>}/>
            <Route path="/dashboard"element={ <><Dashboard /> </>}/>
            <Route path="/all-users" element={ <><AllUsersPage /> </>}/>
            <Route path="/edit-user" element={<><EditUser /></>} />
            <Route path="/add-user" element={<><AddUser /></>}/>
            <Route path="/all-users"element={<><AllUsersPage /></> }/>

            <Route path="/all-landing-slides"element={<><AllLandingSlides /></> }/>
            <Route path="/add-landing-slide"element={<><AddLandingSlide /></> }/>
            <Route path="/edit-landing-slide"element={<><EditLandingSlide /></> }/>

            <Route path="/all-categories"element={<><AllCategories /></> }/>
            <Route path="/add-category"element={<><AddCategory /></> }/>
            <Route path="/edit-category"element={<><EditCategory /></> }/>


            <Route path="/edit-number"element={<><EditNumbers /></> }/>
            <Route path="/all-numbers"element={<><AllNumbers /></> }/>
            <Route path="/edit-benefit"element={<><EditBenefits /></> }/>
            <Route path="/all-benefits"element={<><AllBenefits /></> }/>

            <Route path="/all-videos"element={<><AllVideos /></> }/>
            <Route path="/add-video"element={<><AddVideos /></> }/>
            <Route path="/edit-video"element={<><EditVideos /></> }/>

            <Route path="/all-time-tables"element={<><AllTimeTables /></> }/>
            <Route path="/edit-time-table"element={<><EditTimeTables /></> }/>

            
            <Route path="/add-gallery"element={<><AddGallery /></> }/>
            <Route path="/all-gallery"element={<><AllGalleryPage /></> }/>
            <Route path="/edit-gallery"element={<><EditGallery /></> }/>

            <Route path="/all-about" element={<><AllAbout /></> }/>
            <Route path="/edit-about" element={<><EditAbout /></> }/>

            <Route path="/all-solutions" element={<><AllSolutions /></> }/>
            <Route path="/edit-solution" element={<><EditSolution /></> }/>



            <Route path="/all-gifts" element={<><AllGifts /></> }/>

            <Route path="/all-social" element={<><AllSocialPage /></> }/>
            <Route path="/edit-social" element={<><EditSocial /></> }/>

            <Route path="/all-orders" element={<><AllOrders /></> }/>
            <Route path="/view-orders" element={<><ViewOrders /></> }/>





            <Route path="/all-currency" element={<><AllCurrency /></> }/>
            <Route path="/edit-currency" element={<><EditCurrency /></> }/>


            <Route path="/all-orders" element={<><AllOrders /></> }/>
            <Route path="/view-orders" element={<><ViewOrders /></> }/>


            
            <Route path="/add-product"element={<><AddProduct /></> }/>
            <Route path="/all-products"element={<><AllProducts /></> }/>
            <Route path="/edit-product"element={<><EditProduct /></> }/>


            <Route path="/add-service"element={<><AddServices /></> }/>
            <Route path="/all-services"element={<><AllServices /></> }/>
            <Route path="/edit-service"element={<><EditServices /></> }/>


            <Route path="/add-review"element={<><AddReview /></> }/>
            <Route path="/all-reviews"element={<><AllReviews /></> }/>
            <Route path="/edit-review"element={<><EditReview /></> }/>


            <Route path="/add-blog"element={<><AddBlog /></> }/>
            <Route path="/all-blogs"element={<><AllBlogs /></> }/>
            <Route path="/edit-blog"element={<><EditBlog /></> }/>

            <Route path="/add-job"element={<><AddJob /></> }/>
            <Route path="/all-jobs"element={<><AllJobs /></> }/>
            <Route path="/edit-job"element={<><EditJob /></> }/>




        </Routes>


        
    </div>
);
}

export default App;
