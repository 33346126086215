import './css/ourblogs.css';

import BlogImg1 from './images/blogs/blog1.jpg';
import BlogImg2 from './images/blogs/blog2.jpg';
import BlogImg3 from './images/blogs/blog3.jpg';
import BlogImg4 from './images/blogs/blog4.jpg';
import BlogImg5 from './images/blogs/blog5.webp';


import { Link as RouterLink } from 'react-router-dom';

import React, { useState,useEffect } from 'react';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';



import axios from '../config/index';

function OurBlogs(){

            
    const [Email, setEmail] = useState([]);

    const getEmail = () => {
        let table = "social";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setEmail(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    const [Blogs, setBlogs] = useState([]);

    const getAllblogs = () => {
        axios.post("AllItems", {
            table: "blogs",
        })
        .then((res) => {
            if (res.data) {
                setBlogs(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    
    useEffect(() => {
        getAllblogs();
        getEmail();

    }, []);
    return(
        <>
            <section className='our-blogs'>
                <div className='container'>
                    <div className='our-blogs-content'>
                        <div className='header'>
                            <h3 data-aos="fade-up"> Our Blogs </h3>
                            <h1 data-aos="fade-up">  OFSAERA  Chronicles: Navigating the Frontier of Data Excellence </h1>
                        </div>

                        <Swiper grabCursor={true} spaceBetween={50} slidesPerView={'auto'} navigation={{
                    nextEl: '.next-btn',
                    prevEl: '.prev-btn',
                    }} pagination={false}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay,Navigation,Pagination]}
                        >

                            {
                                Blogs && Blogs.length > 0 && (
                                    Blogs.map((blog, index) => (
                                        <SwiperSlide key={blog.id}>
                                            <div data-aos="zoom-in" className='blog'>
                                                <img src={`uploads/${blog.img}`} alt="blog image"/>
                                                <div className='text'>
                                                    <h1>{blog.header}</h1>
                                                    <h2>ofsaera</h2>

                                                    <p>
                                                        {blog.para.length > 100 ? `${blog.para.slice(0, 100)}...` : blog.para}
                                                    </p>

                                                    <RouterLink to={`/blog?id=${blog.id}`}> Read More <i class="las la-long-arrow-alt-right"></i></RouterLink>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                )
                            }

                            

                        </Swiper>

                        <div data-aos="fade-up" className='blogs-footer'>
                            <h4> Choose ofsaera Now <a href={`mailto:${Email.link}`}> Let's get started </a></h4>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default OurBlogs;