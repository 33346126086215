/* global google */

import './css/navbar.css';
import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect,useRef  } from "react";
import $ from 'jquery';
import React, { useState } from "react";

function Navbar(){



    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (currentScrollPos > 100) {
                $("nav").addClass("active");
            } else {
                $("nav").removeClass("active");
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    

    function handleLanguageSwitch(targetLanguage) {
        // Use window.location.href to navigate to Google Translate with the specified target language.
        window.location.href = `https://translate.google.com/?sl=en&tl=${targetLanguage}`;
    }
    


    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-eles").slideDown(400);
            $("nav").addClass("active");

            $(".nav-eles").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-eles").slideUp(400);
            $("nav").removeClass("active");

        });

        if ($(window).width() < 950) {
            $(".nav-eles ul li:not(.langs-ele)").on("click", function() {
                $(".nav-eles").slideUp(400);
                $("nav").removeClass("active");

            });
        }
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);
    


    
// State variable to track if translation has been initiated
const [translationInitiated, setTranslationInitiated] = useState(false);

// Function to handle the "A" element click
function handleTranslationClick() {
    if (!translationInitiated) {
    // Initialize the Google Translate widget with Arabic as the default language
    new google.translate.TranslateElement(
        { pageLanguage: 'en', includedLanguages: 'ar', autoDisplay: true },
        'google_translate_element'
    );

    // Hide the translation widget's powered by Google branding (optional)
    const branding = document.querySelector('.goog-logo-link');
    if (branding) {
        branding.style.display = 'none';
    }

    // Set the state variable to true to indicate that translation has been initiated
    setTranslationInitiated(true);
    }
}


    return(
        <>

            <nav>
                <div className='container'>
                    <div className='nav-content'>
                        <div className='logo'>
                            <img src={Logo} alt="logo Ofsera" />
                        </div>

                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className="nav-eles">
                        <button id="close-nav"> <i className="las la-times"></i> </button>

                            <ul>
                                <li>
                                    <RouterLink to="/">
                                        Home
                                    </RouterLink>
                                </li>

                                
                                <li>
                                    <RouterLink to="/about-us">
                                        About Us
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/services">
                                        Services
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/careers">
                                        Careers
                                    </RouterLink>
                                </li>


                                <li>
                                    <RouterLink to="/blogs">
                                        Blogs
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/contact-us">
                                        Contact Us
                                    </RouterLink>
                                </li>

                                <li className='langs-ele'>
                                <a className='langs-ele' href="#" onClick={handleTranslationClick}>
                                    العربية <i class="las la-globe-europe"></i>
                                    <div id="google_translate_element"></div>

                                        </a>

                                </li>
                                
                            </ul>
                        </div>

                        <RouterLink to="/contact-us"> Contact Us </RouterLink>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;