import './css/blogpage.css';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from './PageHeader';

import BlogImg1 from './images/blogs/blog1.jpg';
import BlogImg2 from './images/blogs/blog2.jpg';
import BlogImg3 from './images/blogs/blog3.jpg';
import BlogImg4 from './images/blogs/blog4.jpg';
import BlogImg5 from './images/blogs/blog5.webp';

import axios from '../config/index';


function BlogPage() {
const [blogsData, setBlogsData] = useState();

const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const id = queryParams.get('id');

const getBlog = () => {
    let table = "blogs";
    let itemId = id;

    axios.post('get-item', { itemId, table })
        .then((res) => {
            if (res.data.message) {
            } else {
                setBlogsData(res.data);
            }
        })
        .catch((err) => console.log(err));
};

    
useEffect(() => {
    getBlog();
}, []);

return (
    <>
    <PageHeader link1="Home" link2={blogsData?.header} title={blogsData?.header} img={`uploads/${blogsData?.img}`} />
    <section className='blog-page'>
        <div className='container'>
        <div className='blog-page-content'>
            {blogsData ? (
            <>
                <h1>{blogsData.header}</h1>
                <p>{blogsData.para}</p>
            </>
            ) : (
            <p>Blog not found</p>
            )}
        </div>
        </div>
    </section>
    </>
);
}

export default BlogPage;
