import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllSolutions(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [allSolutions, setAllSolutions] = useState([]);

    axios.defaults.withCredentials = true;



    

    const getAllSolutions = ()=>{
        axios.post('AllItems', {
            table: 'solution',
        })
        .then((res) => {
            if (res.data) {
                setAllSolutions(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    





    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }






useEffect(() => {

    getAllSolutions();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <table>

                    <thead>
                        <tr>
                            <th> # </th>
                            <th> Header </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                    {allSolutions.length ? (
                    allSolutions.map((solution) => {
                        return (
                        <tr key={solution.id}>
                            
                            <td>
                            <h6>{solution.id}</h6>
                            </td>


                            <td>
                                <h6>Our Solution Section</h6>
                            </td>
                            

                            <td><Link className='edit' to={`/edit-solution?id=${solution.id}`}> <i class="las la-edit"></i> </Link></td>


                        </tr>
                        );
                    })
                    ) : (
                    <tr>
                        <td colSpan={5}>Loading...</td>
                    </tr>
                    )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllSolutions;